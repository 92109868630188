<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div>
      <br><br><br><br><br>
      <!-- mobile only -->
      <div class="d-xl-none d-lg-none">
        <div class="box-other-right d-flex justify-content-end">
          <div
            class="box-content-right mb-1"
            @click="deposit"
          >
            <div
              class="text-center"
              style="margin-top: 4px"
            >
              <div class="card-menu text-center">
                <span>
                  <i class="text fad fa-wallet fa-2x" />
                  <p class="m-0 text-white">เติมเงิน</p>
                </span>
              </div>
            </div>
          </div>

          <div
            class="box-content-right mb-1"
            @click="withdraw"
          >
            <div
              class="text-center"
              style="margin-top: 4px"
            >
              <div class="card-menu text-center">
                <span>
                  <i class="text fad fa-hands-usd fa-2x" />
                  <p class="m-0 text-white">ถอนเงิน</p>
                </span>
              </div>
            </div>
          </div>

          <div
            class="box-content-right mb-1"
            @click="promotions"
          >
            <div
              class="text-center"
              style="margin-top: 4px"
            >
              <div class="card-menu text-center">
                <span>
                  <i class="text fad fa-badge-dollar fa-2x" />
                  <p class="m-0 text-white">โปรโมชั่น</p>
                </span>
              </div>
            </div>
          </div>

          <div
            class="box-content-right mb-1"
            @click="inviteFriend"
          >
            <div
              class="text-center"
              style="margin-top: 4px"
            >
              <div class="card-menu text-center">
                <span>
                  <i class="text fad fa-users-crown fa-2x" />
                  <p class="m-0 text-white">แนะนำเพื่อน</p>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="game-list">
            <div class="scrollmenu">
              <!-- v-for in div -->
              <div
                v-for="(item, index) in testLoop"
                :key="item.id"
                class="box-game"
              >
                <div
                  v-if="index == 0"
                  class="content-game text-center"
                  @click="getProductAll"
                >
                  <img
                    :src="item.img"
                    alt="games"
                    height="38px"
                  >
                  <p>
                    {{ item.text }}
                  </p>
                </div>
                <div
                  v-else
                  class="content-game text-center"
                  @click="getProduct(item.cat)"
                >
                  <img
                    :src="item.img"
                    alt="games"
                    height="38px"
                  >
                  <p>
                    {{ item.text }}
                  </p>
                </div>
              </div>
              <br><br><br><br>
            </div>
          </div>
          <div class="scrollmenu">
            <div class="game-all">
              <div
                class="row"
                style="padding-left: 10px"
              >
                <div
                  v-for="games in gameListL"
                  :key="games.id"
                  class="col-md-12 col-12"
                >
                  <div class="text-center">
                    <div @click="playGame(games)">
                      <div class="img-game-list2">
                        <img
                          :src="`/games/${games.img}`"
                          :alt="games.productName"
                          width="100%"
                        >
                      </div>
                      <small>{{ games.productName }}</small>
                    </div>
                  </div>
                </div>
                <div
                  v-for="games in gameList"
                  :key="games.id"
                  class="col-md-6 col-6 mb-1"
                >
                  <div class="text-center">
                    <div
                      v-if="games.type === 'FISHING'"
                      @click="playGame(games)"
                    >
                      <div class="img-game-list2">
                        <img
                          :src="games.img"
                          :alt="games.name"
                          width="100%"
                        >
                      </div>
                      <small>{{ games.name }}</small>
                    </div>
                    <div
                      v-else
                      @click="playGame(games)"
                    >
                      <div class="img-game-list2">
                        <img
                          :src="`/games/${games.img}`"
                          :alt="games.productName"
                          width="100%"
                        >
                      </div>
                      <small>{{ games.productName }}</small>
                    </div>
                  </div>
                </div>

              <!-- <div
                v-for="games in gameList"
                :key="games.id"
                class="col-md-4 col-6"
              >
                <router-link
                  :to="{
                    name: 'games-all',
                    params: { productId: games.productId },
                  }"
                >
                  <div class="img-game-list2">
                    <img
                      :src="`/games/live/${games.img}`"
                      :alt="games.productName"
                      width="100%"
                    >
                  </div>
                </router-link>
              </div> -->
              </div>
            </div>
            <br><br><br><br>
          </div>
        </div>
      </div>
      <!-- END mobile only -->

      <!-- Desktop only -->
      <div class="d-none d-lg-block">
        <div>
          <div class="scrollmenu2">
            <div
              v-for="(item, index) in testLoop"
              :key="item.id"
              class="box-game2"
              :class="{
                'active': index == 0,
              }"
            >
              <div
                v-if="index == 0"
                class="content-game text-center"
                @click="getProductAll"
              >
                <img
                  :src="item.img"
                  alt="games"
                  height="38px"
                >
                <p>
                  {{ item.text }}
                </p>
              </div>
              <div
                v-else
                class="content-game text-center"
                @click="getProduct(item.cat)"
              >
                <img
                  :src="item.img"
                  alt="games"
                  height="38px"
                >
                <p>
                  {{ item.text }}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div class="row pl-4 pr-4">
                <div
                  v-for="games in gameListL"
                  :key="games.id"
                  class="col-md-6 col-6"
                >
                  <div class="text-center">
                    <div @click="playGame(games)">
                      <div class="img-game-list2">
                        <img
                          :src="`/games/${games.img}`"
                          :alt="games.productName"
                          width="100%"
                        >
                      </div>
                      <small>{{ games.productName }}</small>
                    </div>
                  </div>
                </div>

                <div
                  v-for="games in gameList"
                  :key="games.id"
                  class="col-md-4 col-6 text-center mb-1"
                >
                  <div
                    v-if="games.type ==='FISHING'"
                    @click="playGame(games)"
                  >
                    <div class="img-game-list2">
                      <img
                        :src="games.img"
                        :alt="games.name"
                        width="100%"
                      >
                    </div>
                    {{ games.name }}

                  </div>
                  <div
                    v-else
                    @click="playGame(games)"
                  >
                    <div class="img-game-list2">
                      <img
                        :src="`/games/${games.img}`"
                        :alt="games.productName"
                        width="100%"
                      >
                    </div>
                    {{ games.productName }}
                  </div>
                </div>
              </div>
            </div>
            <br><br><br><br>
          </div>
        </div>
      </div>
      <!-- END Desktop only -->

      <!-- modal login -->
      <b-modal
        ref="my-modal"
        centered
        hide-footer
        hide-header
      >
        <validation-observer ref="simpleRules">
          <h3
            class="text-center"
            style="color: #000"
          >
            ยืนยันรหัส VIP
          </h3>
          <b-form-group
            label=""
            label-for="a-password"
            class="mt-1"
          >
            <!-- <validation-provider
              #default="{ errors }"
              name="Password"
              vid="Password"
              rules="required|min:8|password"
            > -->
            <b-form-input
              id="a-password"
              v-model="password"
              type="password"
              size="lg"
              placeholder=""
            />
            <!-- <small class="text-danger">{{ errors[0] ? 'กรอกรหัสยืนยัน VIP' : '' }}</small>
            </validation-provider> -->
          </b-form-group>
          <button
            class="m-0 btn"
            @click="CheckPass()"
          >
            ยืนยัน
          </button>
        </validation-observer>
      </b-modal>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BIconController,
  BModal,
  BFormInput, BFormGroup,
  // BFormCheckbox,
} from 'bootstrap-vue'
import BUID from 'uniquebrowserid'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    // ValidationProvider,
    // ValidationObserver,
    BOverlay,
    BIconController,
    BModal,
    BFormInput,
    BFormGroup,
    // BButton,
  },
  data() {
    return {
      testLoop: [
        {
          img: '/mobile/icons/quick-nav-hot-games.png',
          text: 'ทั้งหมด',
        },
        {
          img: '/mobile/icons/quick-nav-egame.png',
          text: 'สล๊อต',
          cat: 'EGAMES',
        },

        {
          img: '/mobile/icons/quick-nav-live.png',
          text: 'คาสิโนสด',
          cat: 'LIVECASINO',
        },
        {
          img: '/mobile/icons/quick-nav-mpg.png',
          text: 'ยิงปลา',
          cat: 'FISHING',
        },
        {
          img: '/mobile/icons/quick-nav-sports.png',
          text: 'กีฬา',
          cat: 'SPORT',
        },
        {
          img: '/mobile/icons/quick-nav-lottery.png',
          text: 'แทงหวย',
          cat: 'LOTTO',
        },
        // {
        //   img: '/mobile/icons/quick-nav-chess.png',
        //   text: 'โป๊กเกอร์',
        //   cat: 'POKER',
        // },

        {
          img: '/mobile/icons/card.png',
          text: 'เกมการ์ด',
          cat: 'CARD',
        },

        {
          img: '/mobile/icons/graph.png',
          text: 'เกมกราฟ',
          cat: 'TRADING',
        },

      ],
      gameList: [],
      gameListL: [],
      userData: JSON.parse(localStorage.getItem('userData')),
      balance: 0,
      turn: null,
      show: true,
      password: null,
    }
  },
  mounted() {
    this.getProductAll()
    this.getbalance()
    this.updateBUID()
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
    updateBUID() {
      const buid = new BUID().completeID()
      const formData = {
        buid,
      }
      this.$http.post('/users/updatebuid', formData)
    },
    deposit() {
      this.$router.push({ name: 'deposit' })
    },
    withdraw() {
      this.$router.push({ name: 'withdraw' })
    },
    promotions() {
      this.$router.push({ name: 'promotions' })
    },
    inviteFriend() {
      this.$router.push({ name: 'invite-friend' })
    },
    TrunUser() {
      this.$http
        .get(
          `/users/trun/${this.$route.params.productId}`,
        )
        .then(response => {
          this.checktrun = response.data.trun
        })
        .catch(error => console.log(error))
    },
    getbalance() {
      this.show = true
      this.$http
        .get('/balance/getbalance')
        .then(response => {
          console.log(response.data)
          this.show = false
          this.balance = response.data.balance
          if (this.balance < 0) {
            this.balance = 0
          }
          this.turn = response.data
        })
        .catch(error => console.log(error))
    },
    playGame(games) {
      if (games.type === 'FISHING') {
        this.play(games.productId, games.code)
      } else if (this.turn.promotionID != null && this.turn.promotionID !== 10) {
        if (games.category === 'EGAMES') {
          this.$router.push({
            name: 'games-all',
            params: {
              productId: games.productId,
              productName: games.productName,
            },
          })
        } else {
          this.SwalError('ท่านมีโปรฯที่ยังทำเทิร์นไม่ครบ ไม่สามารถเล่นเกมนี้ได้ค่ะ')
        }
      } else if (games.category === 'SPORT') {
        if (games.productId === 'SBOBET') {
          this.show = true
          const obj = {
          }
          this.$http
            .post('/sbobet/login', obj)
            .then(response => {
              console.log(response.data)
              this.show = false
              window.location.href = response.data.url
            })
            .catch(error => console.log(error))
        // this.SwalError('พบกับ SBOBET ที่นี่เร็วๆนี้')
        }
      } else if (games.category === 'LOTTO') {
        if (games.productId === 'LOTTO') {
        // Modal
          this.showModal()
        }
      } else {
        this.$router.push({
          name: 'games-all',
          params: {
            productId: games.productId,
            productName: games.productName,
          },
        })
      }
    },
    CheckPass() {
      if (btoa(this.password) === 'UEBwb3dlcjEwMA==') {
        this.LottoLogin()
      }
    },
    LottoLogin() {
      this.show = true
      const obj = {
      }
      this.$http
        .post('/lotto/login', obj)
        .then(response => {
          console.log(response.data)
          this.show = false
          window.location.href = response.data.url
        })
        .catch(error => console.log(error))
    },
    play(productId, gameCode) {
      // console.log(this.$route.params.productId)88
      const obj = {
        username: this.userData.username,
        productId,
        gameCode,
        sessionToken: this.userData.token,
      }
      this.$http
        .post('/product/login', obj)
        .then(response => {
          // console.log(response.data.data.url)
          this.show = false
          window.location.href = response.data.data.url
        })
        .catch(error => console.log(error))
    },
    getProduct(category) {
      this.initvalue()
      this.$http
        .get(`/product/listcat/${category}`)
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.gameList = response.data
        })
        .catch(error => console.log(error))
      this.$http
        .get(`/product/listcatl/${category}`)
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.gameListL = response.data
        })
        .catch(error => console.log(error))
    },
    getProductAll() {
      this.initvalue()
      this.$http
        .get('/product/listall')
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.gameList = response.data
        })
        .catch(error => console.log(error))
      this.$http
        .get('/product/listalll')
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.gameListL = response.data
        })
        .catch(error => console.log(error))
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color:#000">ขออภัยค่ะ!<span>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    initvalue() {
      this.gameList = []
      this.gameListL = []
    },
  },
}
</script>

<style scoped>
.modal .modal-body, .dark-layout .modal .modal-footer {
    background-color: #4e2f2f;
    border-color: #363636;
    border-radius: 14px;
}
.text {
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0.2);
}
div span i {
  margin-top: 5px;
  --fa-primary-color: #000000;
  background: linear-gradient(50deg, #bb995a, #fff7c4 50%, #ac894c);
}
div span p {
  font-size: 12px;
}
.icon {
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0.2);
}
.card {
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5) !important;
  background-color: #ffffff !important;
  color: #000 !important;
}
.container {
  max-width: 1000px !important;
}
.col-md-4,
.col-6 {
  padding: 0.2rem;
}
.img-game-list {
  width: 275px;
  height: 145px;
  background: radial-gradient(circle, #610044, rgb(0, 0, 0));
  border-radius: 15px;
  box-shadow: 0 0 7px #000;
  border: #c9008c 2px solid;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .img-game-list {
    width: 275px;
    height: 145px;
    background: radial-gradient(circle, #610044, rgb(0, 0, 0));
    border-radius: 15px;
    box-shadow: 0 0 7px #000;
    border: #c9008c 2px solid;
    margin-bottom: 10px;
  }
  .img-game-list img {
    width: 255px;
    height: 142px;
    /* border: 1px solid #d30097; */
    border-radius: 14px;
  }
}

.img-game-list img {
  width: 275px;
  height: 142px;
  border-radius: 20px;
}
.img-game-list2 {
  width: 100%;
  height: auto;
  background: radial-gradient(circle, #610044, rgb(0, 0, 0)) padding-box,
    linear-gradient(0deg, #5a499d, #bd6aaa) border-box;
  border-radius: 20px;
  box-shadow: 0 0 7px #000;
  border: 3px solid transparent;
  /* margin-bottom: 10px; */
}
.img-game-list2 img {
  width: 100%;
  height: auto;
  border-radius: 15px;
}
.game-list {
  min-width: 90px;
  height: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}
.box-game {
  margin: auto;
  margin-bottom: 10px;
  width: 70px;
  height: 70px;
  border-radius: 14px;
  background: rgba(34, 34, 34, 0.9);
  box-shadow: 1px 1px 5px 0 #6d0942, inset 0 0 3px #000;
  color: #fd92b3;
  flex-basis: 78px;
  transition: 0.2s;
}
.box-content-right {
  margin-right: 10px;
  width: 65px;
  height: 65px;
  border-radius: 15px;
  background-image: linear-gradient(180deg, #5a499d, #bd6aaa) !important;
}

div.game-all {
  width: 100%;
  height: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 10px;
}
div.scrollmenu {
  min-height: 500px;
  max-height: 600px;
  background-color: transparent;
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu .box-game {
  align-items: center;
  justify-content: center;
  display: block;
  color: white;
  text-align: center;
  padding: 5px;
  text-decoration: none;
}

div.scrollmenu .box-game:hover {
  background: linear-gradient(180deg, #ff3491, #d31e6b);
  box-shadow: 0 1px 5px rgb(109 9 66 / 30%), inset 0 0 0 1px #fd92b3;
}

/***************************** */
.box-game2 {
  cursor: pointer;
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  width: 80px;
  height: 80px;
  border-radius: 10px;
  background: rgba(34, 34, 34, 0.9);
  box-shadow: 0px 1px 1px 0 #6d0942, inset 0 0 3px #000;
  color: #fd92b3;
  flex-basis: 78px;
  transition: 0.2s;
}
div.scrollmenu2 .box-game2:hover {
  background: linear-gradient(180deg, #ff3491, #d31e6b);
  box-shadow: 0 1px 5px rgb(109 9 66 / 30%), inset 0 0 0 1px #fd92b3;
}
div.scrollmenu2 .active {
  background: linear-gradient(180deg, #ff3491, #d31e6b);
  box-shadow: 0 1px 5px rgb(109 9 66 / 30%), inset 0 0 0 1px #fd92b3;
}

div.scrollmenu2 {
  text-align: center;
  background-color: transparent;
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu2 .box-game2 {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}
.btn {
  border-radius: 10px;
  width: 100%;
  border: 0;
  color: #fff !important;
  background: linear-gradient(318.11deg,#341082 31.96%,#47C6EF 103.83%);
    /* color: #000; */
}
.form-control {
  border-radius: 10px;
  border: 2px solid rgba(24, 50, 121, 0.719);
  background: linear-gradient(318.11deg,#c2c2c2 31.96%,#c4c4c4fd 103.83%);
  color: rgb(0, 0, 0);
}
</style>
